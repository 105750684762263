<template>
  <div>
      <div class="carousel-form">
        <el-select v-model="status" style="width:100px" placeholder="全部">
            <el-option label="全部" value="all"></el-option>
            <el-option label="已屏蔽" value="0"></el-option>
            <el-option label="未屏蔽" value="1"></el-option>
        </el-select>
        <el-input v-model="word" style="width:200px"></el-input> <el-button type="primary" @click="init()">检索</el-button>
      </div>
      <div class="carousel-table">
            <el-table
              :data="lsit"
              border
              style="width: 100%">
              <el-table-column
                type="index">
              </el-table-column>
             <el-table-column
                prop="articleTitle"
                label="评论文章"
                width="200">
              </el-table-column>
              <el-table-column
                prop="content"
                label="评论信息"
                width="200">
              </el-table-column>
              <el-table-column
                prop="userName"
                label="评论人"
                width="200">
              </el-table-column>
              <el-table-column
                prop="time"
                label="评论时间"
                width="230">
              </el-table-column>
              <el-table-column
                prop="shieldStatus"
                label="是否已屏蔽"
                width="230">
                 <template slot-scope="scope">
                  <span>{{ (scope.row.shieldStatus==0?"否":"是") }} </span>
                </template>
              </el-table-column>
              <el-table-column
                label="操作"
                width="230">
                <template slot-scope="scope">
                  <el-button @click="shieldThis(scope.row)" type="text" size="small">{{ (scope.row.shieldStatus==0?"屏蔽":"取消屏蔽") }}</el-button>
                </template>
              </el-table-column>
            </el-table>
      </div>
      <div style="position:relative;width:100%;height:30px;padding-top:20px">  
          <el-pagination style="position:absolute;right:0"
              @size-change="handleSizeChange"
              @current-change="currentPage"
              :current-page="currentPg"
              :page-sizes="[20, 30, 50, 100]"
              :page-size="pageSize"
              layout="total, sizes, prev, pager, next, jumper"
              :total="total">
          </el-pagination>
      </div>
  </div>
  
</template>

<script>
import {QUERY,UPDATE,DELETE}  from '@/services/dao.js';
export default {    
    data(){
        return {
            lsit: [{
                id: 2,
                articleTitle: '范德萨范德萨',
                content:"fdsfsdfdsfds",
                userName:1,
                time:"存储存储错错",
                shieldStatus:0
          }],
           status:"all",
           currentPg:0,
           pageSize:20,
           total:800,
           word:""
        }
    },
    created(){
      this.init();
    },
    methods: {
        init(){
            this.searchInfo(this.pageSize,0);
        },
        async shieldThis(row){
           
            let info=null;
            if(row.shieldStatus===0){
              info= await UPDATE("post","",'  update_Comment(where: {id: {_eq: '+row.id+'}}, _set: {isShield: 1}) {     affected_rows   }');
                if(info.data.errors||info.data.update_Comment.affected_rows<=0){
                    //alert("屏蔽失败!");
                     this.$message({
                      message: '屏蔽失败!',
                      type: 'warning'
                    });
                  return;
                }
                row.shieldStatus=1;
            }else{
              info=  await UPDATE("post","",'  update_Comment(where: {id: {_eq:  '+row.id+'}}, _set: {isShield: 0}) {     affected_rows   }');
              if(info.data.errors||info.data.update_Comment.affected_rows<=0){
                    //alert("取消屏蔽成功!");
                    this.$message({
                      message: '取消屏蔽成功!',
                      type: 'success'
                    });
                  return;
                }
                 row.shieldStatus=0;
            }
        },
        async  searchInfo(limit,offset){
            let info=null;
            if(this.status=="all"){
              info=  await  QUERY("post","",  'Comment(limit: '+limit+', offset:'+offset+', where: {content: {_like: "%'+this.word+'%"}}) {     commentator     whole      id     content     isShield     time     User {       id       name     }     TtArticle {       title       id     }   }   Comment_aggregate(where: { content: {_like: "%'+this.word+'%"}}) {     aggregate {       count     }   }');
            }else{
              info=  await  QUERY("post","",  'Comment(limit: '+limit+', offset:'+offset+', where: {content: {_like: "%'+this.word+'%"}, isShield: {_eq: '+this.status+'}}) {     commentator     whole      id     content     isShield     time     User {       id       name     }     TtArticle {       title       id     }   }   Comment_aggregate(where: {isShield: {_eq: '+this.status+'}, content: {_like: "%'+this.word+'%"}}) {     aggregate {       count     }   }');
           
            }
             if(this.lsit.length){
                this.lsit.splice(0,this.lsit.length);
            }
            info.data.Comment.forEach(element => {
              this.lsit.push({
                id:element.id,
                articleTitle:element.TtArticle.title,
                content:element.content,
                userName:element.User.name,
                time:element.time,
                shieldStatus:element.isShield
              });
            });
            this.total=info.data.Comment_aggregate.aggregate.count;
        },
        //前一页
        prevPage(val){
            this.pageNum=val-1;
            this.searchInfo(this.pageSize,this.pageSize*this.pageNum);
        },
        //下一页
        nextPage(val){
            this.pageNum=val-1;
            this.searchInfo(this.pageSize,this.pageSize*this.pageNum);
        },
        //当前页
        currentPage(val){
            console.log(val);
            this.pageNum=val-1;
            this.searchInfo(this.pageSize,this.pageSize*this.pageNum);
        },
        //切换每页条数
        handleSizeChange(val){
            this.pageSize=val;
           this.searchInfo(this.pageSize,0);
        },
    }
}
</script>

<style scoped>
 .carousel-table{
     margin-top: 20px;
 }
</style>